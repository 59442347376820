



































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { CommentsService, Comments, Comment } from '@/api'
import formatter from '@/helpers'

@Component
export default class CommentView extends Vue {
  @Prop(String) id!: string

  public comments: Comments = []

  public parsedID = 1

  formatDate (val: string | undefined): string {
    return formatter.formatDate(val)
  }

  comment (): Comment {
    let comment: Comment = {
      id: 0,
      name: '',
      text: '',
      date: ''
    }

    this.comments.forEach(val => {
      if (val.parent_id !== undefined) {
        return
      }

      comment = val
    })

    return comment
  }

  responses (): Comments {
    const resp: Comments = []

    this.comments.forEach(val => {
      if (val.parent_id === undefined) {
        return
      }

      resp.push(val)
    })

    return resp
  }

  @Watch('id')
  idChanged () {
    this.parsedID = this.getID(this.id)

    this.getComment()
  }

  mounted () {
    this.parsedID = this.getID(this.id)

    this.getComment()
  }

  getID (val: string): number {
    const id = Number.parseInt(val, 10)
    if (Number.isNaN(id)) {
      return 1
    }

    return id
  }

  getComment () {
    CommentsService.getComment({ id: this.parsedID }).then(value => {
      this.comments = value
    })
  }
}
