








import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class Comments extends Vue {
  mounted () {
    // const js = document.createElement('script')
    // js.src = '/js/vk.js'
    // document.head.appendChild(js)
  }
}
