










































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Comments from '@/components/Comments.vue'
import helpers from '@/helpers'

@Component({ components: { Comments } })
export default class Contacts extends Vue {
  public showServices = true
  public showComments = true

  mounted () {
    this.showServices = this.checkShowServices(this.$router.currentRoute.path)
    this.showComments = this.checkShowComments(this.$router.currentRoute.path)

    this.$router.afterEach(to => {
      this.showServices = this.checkShowServices(to.path)
      this.showComments = this.checkShowComments(this.$router.currentRoute.path)
    })
  }

  checkShowServices (path: string): boolean {
    switch (path) {
      case '/dissertaciya-pomozhem-napisat.html': {
        return false
      }
      case '/kursovaya-za-1-den.html': {
        return false
      }
      case '/referat-za-den.html': {
        return false
      }
      case '/diplom-srochnaya-pomoshch-za-1-den.html': {
        return false
      }
      case '/': {
        return false
      }
      default: {
        return true
      }
    }
  }

  checkShowComments (path: string): boolean {
    switch (path) {
      case '/': {
        return false
      }
      default: {
        return true
      }
    }
  }

  startCallTime (): string {
    return helpers.startCallTime()
  }

  endCallTime (): string {
    return helpers.endCallTime()
  }

  tzDiffer (): boolean {
    return helpers.tzDiffer()
  }
}
