









import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class Benefit extends Vue {
  @Prop({ default: '' }) image!: string
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) description!: string
}
