




import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ArticlesService, Article } from '@/api'

@Component
export default class CommentView extends Vue {
  @Prop(String) path!: string

  public article: Article = {
    text: ''
  }

  @Watch('path')
  idChanged () {
    this.getArticle()
  }

  mounted () {
    this.getArticle()
  }

  getArticle () {
    ArticlesService.getArticle({ path: this.path }).then(value => {
      this.article = value
    })
  }
}
