























import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { CommentsService, CommentsData } from '@/api'
import formatter from '@/helpers'

const commentsPerPage = 10

@Component
export default class CommentsList extends Vue {
  @Prop(String) id!: string

  public comments: CommentsData = {
    items: [],
    count: 0
  }

  public parsedID = 1

  formatDate (val: string | undefined): string {
    return formatter.formatDate(val)
  }

  pages (): number {
    return Math.ceil(this.comments.count / commentsPerPage)
  }

  @Watch('id')
  idChanged () {
    this.parsedID = this.getID(this.id)

    this.getComments()
  }

  mounted () {
    this.parsedID = this.getID(this.id)

    this.getComments()
  }

  getID (val: string): number {
    const id = Number.parseInt(val, 10)
    if (Number.isNaN(id)) {
      return 1
    }

    return id
  }

  getComments () {
    let offset = this.parsedID - 1
    if (offset < 0) {
      offset = 0
    }

    offset = offset * commentsPerPage

    CommentsService.getComments({ limit: commentsPerPage, offset: offset }).then(value => {
      this.comments = value
    })
  }
}
